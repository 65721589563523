<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getSaleReceivingAddressPage"
      :deleteFun="deleteReceivingAddress"
      :createFun="createReceivingAddress"
      :updateFun="updateReceivingAddress">
    </base-page>
  </a-card>
</template>

<script>
import { BasePage } from '@/components'
import { getSaleReceivingAddressPage, deleteReceivingAddress, createReceivingAddress, updateReceivingAddress } from '@/api/wms/sale'

export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: `送货方式`, name: `name`, fieldType: ``, queryType: `%`
        }
      ],
      columns: [
        {
          title: `#`,
          scopedSlots: { customRender: `serial` },
          width: 50,
          align: `center`
        },
        {
          title: `送货方式`,
          dataIndex: `name`
        },
        {
          title: `操作`,
          dataIndex: `action`,
          width: 120,
          scopedSlots: { customRender: `action` }
        }
      ],
      rules: {
        name: [{ required: true, message: `请输入送货方式`, trigger: `blur` }]
      },
      modelTitle: `送货方式`,
      formFields: [
        {
          label: `送货方式`, name: `name`, type: `default`
        }
      ],
      moduleName: `escort_mode`
    }
  },
  methods: {
    getSaleReceivingAddressPage,
    deleteReceivingAddress,
    createReceivingAddress,
    updateReceivingAddress
  }
}
</script>
